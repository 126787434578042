var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import DashboardLayout from "@/views/Layout/DashboardLayout";
import { useRecoilValue } from "recoil";
import { userState } from "../Auth/state";
import { useAPI } from "@/hooks/useAPI";
import { merchantState } from "./state";
import Transactions from "@/components/Tables/Transactions";
import DashboardChart from "@/components/Chart/DashboardChart";
import { Roles } from "@/enums/Roles";
import { useNavigate } from "react-router-dom";
var Home = function () {
    var navigate = useNavigate();
    var user = useRecoilValue(userState);
    var hitAPI = useAPI();
    // Set initial start date to be a week ago
    var currentDate = new Date();
    var _a = useState(new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000)), startDate = _a[0], setStartDate = _a[1];
    var _b = useState(null), statistics = _b[0], setStatistics = _b[1];
    var merchant = useRecoilValue(merchantState);
    var getStatistics = function (merchant) { return __awaiter(void 0, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, hitAPI("/merchant/".concat(merchant.id, "/statistics?start_date=").concat(startDate.toLocaleDateString("sv"), "&end_date=").concat(currentDate.toLocaleDateString("sv")))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    return [2 /*return*/, data.data];
            }
        });
    }); };
    var getData = function (merchant) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = setStatistics;
                    return [4 /*yield*/, getStatistics(merchant)];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        merchant && getData(merchant);
    }, [startDate]);
    useEffect(function () {
        merchant &&
            merchant.role_id === Roles.Staff &&
            navigate("/transactions");
    }, []);
    return (_jsxs(DashboardLayout, { title: "Welcome back, ".concat(user === null || user === void 0 ? void 0 : user.name), children: [_jsxs("div", { className: "bg-white rounded-2xl py-6", style: {
                    boxShadow: "2px 4px 6px 0px rgba(72, 83, 73, 0.04)",
                }, children: [_jsxs("div", { className: "flex justify-between px-5", children: [_jsx("h1", { className: "text-xl pb-4 text-[#123750]", children: "Statistics" }), _jsxs("select", { onChange: function (e) {
                                    var value = e.target.value;
                                    var currentDate = new Date();
                                    var newStartDate;
                                    switch (value) {
                                        case "1 week":
                                            newStartDate = new Date(currentDate.getTime() -
                                                7 * 24 * 60 * 60 * 1000);
                                            break;
                                        case "1 month":
                                            newStartDate = new Date(currentDate.getTime() -
                                                30 * 24 * 60 * 60 * 1000);
                                            break;
                                        case "3 months":
                                            newStartDate = new Date(currentDate.getTime() -
                                                90 * 24 * 60 * 60 * 1000);
                                            break;
                                        default:
                                            newStartDate = new Date(currentDate.getTime() -
                                                7 * 24 * 60 * 60 * 1000);
                                            break;
                                    }
                                    setStartDate(newStartDate);
                                }, className: "px-4 py-3 border border-[#E8E8ED] rounded-lg text-[#737373]", children: [_jsx("option", { value: "1 week", children: "1 week" }), _jsx("option", { value: "1 month", children: "1 month" }), _jsx("option", { value: "3 months", children: "3 month" })] })] }), _jsx("div", { className: "grid grid-cols-2 gap-2 px-5", children: statistics && (_jsxs(_Fragment, { children: [_jsx(DashboardChart, { data: statistics.transactions, title: "Transactions" }), _jsx(DashboardChart, { data: statistics.revenue, title: "Revenues", with_dollar_sign: true })] })) })] }), _jsxs("div", { className: "bg-white rounded-2xl py-6", style: {
                    boxShadow: "2px 4px 6px 0px rgba(72, 83, 73, 0.04)",
                }, children: [_jsx("h1", { className: "text-xl px-5 pb-4 text-[#123750]", children: "Recent Transactions" }), _jsx(Transactions, { count: 10, shouldPaginate: false, filters: {
                            start_date: "",
                            end_date: "",
                            channel: [1, 2, 3, 4, 5, 6],
                            status: [1],
                        } })] })] }));
};
export default Home;
