import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import Login from "./views/Auth/Login";
import ForgotPassword from "./views/Auth/ForgotPassword";
import { ProtectedRoute } from "./views/Layout/ProtectedRoute";
import { GuestOnlyRoute } from "./views/Layout/GuestOnlyRoute";
import AuthLayout from "./views/Auth";
import ResetPassword from "./views/Auth/ForgotPassword/reset";
import { Suspense } from "react";
import SignUp from "./views/Auth/SignUp";
import Loader from "./views/Loader";
import TransactionsPage from "./views/Transactions";
import PaymentRequestPage from "./views/PaymentLink";
import PaymentRequestCreatePage from "./views/PaymentLink/create";
import SettlementsPage from "./views/Settlements";
import SettlementDetailPage from "./views/Settlements/detail";
import Logout from "./views/Auth/Logout";
import NotificationPage from "./views/Notifications";
import AccountSettingsPage from "./views/AccountSetting";
import MerchantSettingsPage from "./views/MerchantSetting";
import TransactionDetailPage from "./views/Transactions/detail";
import TermsAndConditionsPage from "./views/TermsAndConditions";
import PrivacyPolicyPage from "./views/PrivacyPolicy";
import DashboardIcon from "./components/Icons/DashboardIcon";
import DollarIcon from "./components/Icons/DollarIcon";
import BellIcon from "./components/Icons/BellIcon";
import DocumentIcon from "./components/Icons/DocumentIcon";
import IncomingDollarIcon from "./components/Icons/IncomingDollarIcon";
import SettingIcon from "./components/Icons/SettingIcon";
import { Roles } from "./enums/Roles";
export var availableMenu = {
    home: [
        {
            title: "Dashboard",
            icon: DashboardIcon,
            link: "/",
            exact_match: true,
            allowed_role: [
                Roles.Admin,
                Roles.Manager,
                Roles.Owner
            ],
        },
        {
            title: "Notifications",
            icon: BellIcon,
            link: "/notifications",
            allowed_role: [
                Roles.Admin,
                Roles.Manager,
                Roles.Owner,
                Roles.Staff,
            ],
        },
        {
            title: "Transactions",
            icon: DollarIcon,
            link: "/transactions",
            allowed_role: [
                Roles.Admin,
                Roles.Owner,
                Roles.Manager,
                Roles.Staff,
                Roles.Terminal,
            ],
        },
        // {
        //     title: "Customers",
        //     icon: PeopleIcon,
        //     link: "/customers",
        // },
        {
            title: "Settlements",
            icon: DocumentIcon,
            link: "/settlements",
            allowed_role: [
                Roles.Admin,
                Roles.Manager,
                Roles.Owner
            ],
        },
        {
            title: "Payment Requests",
            icon: IncomingDollarIcon,
            link: "/payment-requests",
            allowed_role: [
                Roles.Admin,
                Roles.Owner,
                Roles.Manager,
                Roles.Staff,
                Roles.Terminal
            ],
        },
    ],
    configure: [
        // {
        //     title: "Products",
        //     icon: ProductIcon,
        //     link: "/products",
        // },
        // {
        //     title: "Payment Methods",
        //     icon: CardIcon,
        //     link: "/payment-methods",
        // },
        {
            title: "Account Settings",
            icon: SettingIcon,
            link: "/account/settings",
            allowed_role: [
                Roles.Admin,
                Roles.Manager,
                Roles.Owner,
                Roles.Staff,
            ],
        },
        {
            title: "Merchant Settings",
            icon: SettingIcon,
            link: "/merchant/settings",
            allowed_role: [
                Roles.Admin,
                Roles.Owner
            ],
        },
    ],
    // support: [
    //     {
    //         title: "FAQs",
    //         icon: DashboardIcon,
    //         link: "/faqs",
    //     },
    //     {
    //         title: "Support Ticket",
    //         icon: SupportIcon,
    //         link: "/help",
    //     },
    // ],
};
function AppRoutes() {
    return (_jsx(Suspense, { fallback: _jsx(Loader, {}), children: _jsxs(Routes, { children: [_jsx(Route, { element: _jsx(GuestOnlyRoute, {}), children: _jsxs(Route, { path: "/auth", element: _jsx(AuthLayout, {}), children: [_jsx(Route, { path: "login", element: _jsx(Login, {}) }), _jsx(Route, { path: "sign-up", element: _jsx(SignUp, {}) }), _jsx(Route, { path: "forgot-password", element: _jsx(ForgotPassword, {}) }), _jsx(Route, { path: "forgot-password/reset", element: _jsx(ResetPassword, {}) })] }) }), _jsxs(Route, { element: _jsx(ProtectedRoute, {}), children: [_jsx(Route, { path: "/", element: _jsx(Home, {}) }), _jsx(Route, { path: "/auth/logout", element: _jsx(Logout, {}) }), _jsx(Route, { path: "/transactions", element: _jsx(TransactionsPage, {}) }), _jsx(Route, { path: "/transactions/:id", element: _jsx(TransactionDetailPage, {}) }), _jsx(Route, { path: "/notifications", element: _jsx(NotificationPage, {}) }), _jsx(Route, { path: "/payment-requests", element: _jsx(PaymentRequestPage, {}) }), _jsx(Route, { path: "/payment-requests/create", element: _jsx(PaymentRequestCreatePage, {}) }), _jsx(Route, { path: "/settlements", element: _jsx(SettlementsPage, {}) }), _jsx(Route, { path: "/settlements/:id", element: _jsx(SettlementDetailPage, {}) }), _jsx(Route, { path: "/account/settings", element: _jsx(AccountSettingsPage, {}) }), _jsx(Route, { path: "/merchant/settings", element: _jsx(MerchantSettingsPage, {}) })] }), _jsx(Route, { path: "/tnc", element: _jsx(TermsAndConditionsPage, {}) }), _jsx(Route, { path: "/privacy-policy", element: _jsx(PrivacyPolicyPage, {}) })] }) }));
}
export default AppRoutes;
