var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Thead from "@/components/Table/Thead";
import Tr from "@/components/Table/Tr";
import Table from "@/components/Table/Table";
import Th from "@/components/Table/Th";
import Tbody from "@/components/Table/Tbody";
import Td from "@/components/Table/Td";
import { useEffect, useState } from "react";
import { useAPI } from "@/hooks/useAPI";
import { useRecoilValue } from "recoil";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { merchantState } from "@/views/Home/state";
export default function Settlements() {
    var _this = this;
    var hitAPI = useAPI();
    var navigate = useNavigate();
    var merchant = useRecoilValue(merchantState);
    var _a = useState({
        data: [],
        meta: {
            current_page: 1,
            last_page: 1,
            per_page: 15,
            total: 0,
        },
    }), data = _a[0], setData = _a[1];
    var getData = function (page, per_page) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!merchant)
                        return [2 /*return*/];
                    return [4 /*yield*/, hitAPI("/merchant/".concat(merchant.id, "/payouts?per_page=").concat(per_page !== null && per_page !== void 0 ? per_page : data.meta.per_page, "&page=").concat(page !== null && page !== void 0 ? page : data.meta.current_page))];
                case 1:
                    response = _b.sent();
                    _a = setData;
                    return [4 /*yield*/, response.json()];
                case 2:
                    _a.apply(void 0, [(_b.sent()).data]);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getData();
    }, []);
    return (_jsxs(Table, { pagination: __assign(__assign({}, data.meta), { changePage: function (page) { return getData(page); }, changePerPage: function (perPage) { return getData(1, perPage); } }), children: [_jsx(Thead, { children: _jsxs(Tr, { children: [_jsx(Th, { children: "Date & Time" }), _jsx(Th, { children: "Amount" }), _jsx(Th, { children: "Bank" }), _jsx(Th, { children: "Provider" }), _jsx(Th, { children: "Status" })] }) }), _jsx(Tbody, { children: data.data.map(function (payout) {
                    var d = moment(payout.transaction_date);
                    var formatted = d.format("DD-MM-YYYY hh:mm:ss");
                    return (_jsxs(Tr, { className: "cursor-pointer", onClick: function () {
                            return navigate("/settlements/".concat(payout.id));
                        }, children: [_jsx(Td, { children: formatted }), _jsxs(Td, { children: ["$", payout.amount.toFixed(2)] }), _jsx(Td, { children: payout.bank_name }), _jsx(Td, { children: payout.provider }), _jsx(Td, { children: payout.status === "NOT_PAID" ? (_jsx("span", { className: "bg-[#FFAE5033] bg-opacity-20 text-[#FFAE50] px-3 py-1 rounded-md", children: "PENDING" })) : (_jsx("span", { className: "bg-[#1BC5BD33] bg-opacity-20 text-[#1BC5BD] px-3 py-1 rounded-md", children: "PAID" })) })] }, payout.id));
                }) })] }));
}
