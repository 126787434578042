import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import ArrowUpWithTail from "../Icons/ArrowUpWithTail";
import Chart from "react-apexcharts";
import moment from "moment";
export default function DashboardChart(_a) {
    var data = _a.data, title = _a.title, _b = _a.with_dollar_sign, with_dollar_sign = _b === void 0 ? false : _b;
    var prepareChartOptions = function () {
        return {
            chart: {
                // Generate random ID
                id: Math.random().toString(36).substr(2, 9),
                toolbar: {
                    show: false,
                },
            },
            grid: {
                borderColor: "#D7DBDE",
                strokeDashArray: 4,
            },
            markers: {
                size: 4,
                radius: 6,
                colors: "#A9C1FD",
                strokeOpacity: 0,
            },
            stroke: {
                curve: "smooth",
                width: 2,
                colors: ["#336CFB"],
            },
            xaxis: {
                categories: data.data.map(function (statistic) {
                    var d = moment(statistic.date);
                    return d.format("DD MMM");
                }),
                labels: {
                    style: {
                        colors: "#A0A4A8",
                        fontFamily: "Poppins",
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: "#A0A4A8",
                        fontFamily: "Poppins",
                    },
                },
            },
        };
    };
    var prepareChartData = function () {
        return [
            {
                name: title,
                data: data.data.map(function (statistic) { return statistic.count; }),
            },
        ];
    };
    return (_jsxs("div", { children: [_jsxs("h1", { className: "text-xl text-[#1BC5BD] mb-2 text-left", children: ["Total ", title] }), _jsxs("div", { className: "flex gap-2", children: [_jsx("p", { className: "text-[#123750] font-semibold text-2xl", children: with_dollar_sign
                            ? "$".concat(parseFloat(data.count).toFixed(2))
                            : data.count }), _jsxs("span", { className: "bg-opacity-20 px-3 py-1 rounded-lg ".concat(data.percentage > 0
                            ? "bg-[#1BC5BD33] text-[#1BC5BD]"
                            : "bg-[#FF6A6533] text-[#FF6A65]", "  flex items-center gap-1"), children: [data.percentage, "%", data.percentage > 0 ? (_jsx(ArrowUpWithTail, { className: "fill-[#1BC5BD]" })) : (_jsx(ArrowUpWithTail, { className: "fill-[#FF6A65] rotate-180" }))] })] }), _jsx(Chart, { type: "line", options: prepareChartOptions(), series: prepareChartData() })] }));
}
